import { inject } from 'mobx-react';
import React, { ReactNode } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Bulletins, IBaseModuleProps, NO_SQL_COLLECTIONS, UpsertBulletin } from '@wings/shared';
import { AuthStore, SettingsModuleSecurity } from '@wings-shared/security';
import {
  AirportHours,
  AirportHoursDetails,
  AirportMapping,
  AirportMappingBeta,
  FAAImport,
  FAAImportRoutes,
  Settings,
  CoreModule,
} from './Modules';
import { UpsertAirport } from './Modules/Core';
import { AirportModuleSecurity, airportSidebarOptions, updatedAirportSidebarOptions } from './Modules/Shared';
import { NotFoundPage, SearchStore } from '@wings-shared/core';
import { ProtectedRoute } from '@wings-shared/layout';

@inject('sidebarStore')
export default class AirportsApp extends React.Component<IBaseModuleProps> {
  private readonly redirectPath: string = '/airports';

  constructor(props) {
    super(props);
    this.props.sidebarStore?.setNavLinks(airportSidebarOptions(true), this.props.basePath);
    // Needs to set permissions after lazy loading
    AirportModuleSecurity.init();
    SettingsModuleSecurity.updatePermissions();
    AuthStore.configureAgGrid();
  }

  // 81904 clear search store on application unmount
  componentWillUnmount(): void {
    SearchStore.clearSearch();
  }

  public render(): ReactNode {
    return (
      <Routes>
        <Route path="airports/*">
          <Route index element={<CoreModule />} />
          <Route path="upsert/:viewMode/*" element={<UpsertAirport key={'airport-details-add-update'} />} />
          <Route
            path="upsert/:airportId/:viewMode/*"
            element={<UpsertAirport key={'airport-details-add-update-no-icao'} />}
            key="airport-details-add-update-no-icao"
          />
          <Route
            path="upsert/:airportId/:icao/:viewMode/*"
            element={<UpsertAirport key={'airport-icao-details-add-update'} />}
            key="airport-icao-details-add-update"
          />
          <Route path="airport-hours" element={<AirportHours />} />
          <Route path="import-faa" element={<FAAImport />} />
          <Route path="import-faa/:id/:processId/*" element={<FAAImportRoutes />} />
          <Route
            path="airport-hours/:viewMode"
            key="new"
            element={
              <ProtectedRoute
                hasPermission={AirportModuleSecurity.isEditable}
                redirectPath={this.redirectPath}
                element={<AirportHoursDetails key={'airport-hours-new'} />}
              />
            }
          />
          <Route
            path="airport-hours/:airportId/:icao/new" // Required when user comes from airport screen
            key="new-with-icao"
            element={
              <ProtectedRoute
                element={<AirportHoursDetails key={'airport-hours-new-with-icao'} />}
                hasPermission={AirportModuleSecurity.isEditable}
                redirectPath={this.redirectPath}
              />
            }
          />
          <Route
            path="airport-hours/:airportId/:icao/:airportHoursTypeId/:viewMode/*"
            key=""
            element={<AirportHoursDetails key={'airport-hours-from-airport-screen'} />}
          />
          <Route
            path="airport-hours/:icao/:airportHoursTypeId/:viewMode/*"
            key="details"
            element={<AirportHoursDetails key={'airport-hour-details'} />}
          />
          <Route path="airport-mappings" element={<AirportMapping />} />
          <Route path="airport-mappings-beta" element={<AirportMappingBeta />} />
          <Route path="settings" element={<Settings />} />
          <Route
            path="bulletins"
            element={
              <Bulletins
                defaultSidebarOptions={airportSidebarOptions}
                securityModule={AirportModuleSecurity}
                collectionName={NO_SQL_COLLECTIONS.AIRPORT_BULLETIN}
                basePath="/airports"
              />
            }
          />
          <Route
            path="bulletins/:viewMode"
            element={
              <UpsertBulletin
                updatedSidebarOptions={() => updatedAirportSidebarOptions('Bulletins')}
                securityModule={AirportModuleSecurity}
                basePath="/airports"
                key={'bulletins-view'}
              />
            }
          />
          <Route
            path="bulletins/:bulletinId/:viewMode"
            key="bulletin-details"
            element={
              <UpsertBulletin
                updatedSidebarOptions={() => updatedAirportSidebarOptions('Bulletins')}
                key={'bulletin-details'}
                securityModule={AirportModuleSecurity}
                basePath="/airports"
              />
            }
          />
          <Route
            path="purged-bulletins"
            element={
              <Bulletins
                purgedBulletins={true}
                defaultSidebarOptions={airportSidebarOptions}
                securityModule={AirportModuleSecurity}
                basePath="/airports"
                key={'purged-bulletins'}
              />
            }
          />
          <Route
            path="purged-bulletins/:purgedBulletinId/:viewMode"
            element={
              <UpsertBulletin
                purgedBulletins={true}
                updatedSidebarOptions={() => updatedAirportSidebarOptions('Purged Bulletins')}
                securityModule={AirportModuleSecurity}
                basePath="/airports"
                key={'purged-bulletin-details'}
              />
            }
          />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    );
  }
}
