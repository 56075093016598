import { CoreModel, modelProtection } from '@wings-shared/core';
import { IAPIAirportCustom } from '../Interfaces';
import { AirportCustomGeneralModel } from './AirportCustomGeneral.model';

@modelProtection
export class AirportCustomModel extends CoreModel {
  generalInformation: AirportCustomGeneralModel = new AirportCustomGeneralModel();

  constructor(data?: Partial<AirportCustomModel>) {
    super(data);
    Object.assign(this, data);
  }

  static deserialize(apiData: IAPIAirportCustom): AirportCustomModel {
    if (!apiData) {
      return new AirportCustomModel();
    }
    const data: Partial<AirportCustomModel> = {
      ...apiData,
      generalInformation: AirportCustomGeneralModel.deserialize(apiData.generalInformation),
      ...CoreModel.deserializeAuditFields(apiData),
    };
    return new AirportCustomModel(data);
  }

  static deserializeList(apiDataList: IAPIAirportCustom[]): AirportCustomModel[] {
    return apiDataList ? apiDataList.map(apiData => AirportCustomModel.deserialize(apiData)) : [];
  }
}
