import { EDITOR_TYPES, IGroupInputControls, ViewInputControlsGroup } from '@wings-shared/form-controls';
import { IUseUpsert, withFormFields } from '@wings/shared';
import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { generalFields } from '../fields';
import {
  AirportSettingsStore,
  AirportStore,
  AirportCustomGeneralModel,
  EntityMapStore,
  AirportModel,
} from '../../../../Shared';
import { IOptionValue } from '@wings-shared/core';
import { useStyles } from '../CustomDetails.styles';

interface Props {
  useUpsert: IUseUpsert;
  airportSettingsStore?: AirportSettingsStore;
  airportStore?: AirportStore;
  entityMapStore?: EntityMapStore;
}

const GeneralInfo = ({ useUpsert, airportSettingsStore, airportStore, entityMapStore }: Props) => {
  const classes = useStyles();
  const _entityMapStore = entityMapStore as EntityMapStore;
  const _airportStore = airportStore as AirportStore;
  const _airportSettingStore = airportSettingsStore as AirportSettingsStore;

  useEffect(() => {
    loadInitialData();
    return () => {
      useUpsert.form.reset();
    };
  }, []);

  const loadInitialData = () => {
    const { airportOfEntry, customs } = _airportStore.selectedAirport as AirportModel;
    const general = {
      ...customs?.generalInformation,
      airportOfEntry,
    };
    useUpsert.setFormValues(general || new AirportCustomGeneralModel());
  };

  const onFocus = (fieldKey: string) => {
    if (fieldKey === 'airportOfEntry') {
      useUpsert.observeSearch(_airportSettingStore.loadAirportOfEntries());
      return;
    }
    useUpsert.observeSearch(_entityMapStore.loadEntities(fieldKey));
  };

  const onSearch = (searchValue: string, fieldKey: string) => {
    useUpsert.observeSearch(_entityMapStore.searchEntities(searchValue, fieldKey));
  };

  const onValueChange = (value: IOptionValue | IOptionValue[], fieldKey: string) => {
    useUpsert.onValueChange(value, fieldKey);
    switch (fieldKey) {
      case 'customOfficerDispacthedFromAirport':
        if (!value) {
          _entityMapStore.airports = [];
        }
        break;
      case 'customsClearanceFBOs':
        if (!(value as IOptionValue[]).length) {
          _entityMapStore.vendorLocations = [];
        }
        break;
    }
  };
  const groupInputControls = (): IGroupInputControls[] => [
    {
      title: '',
      inputControls: [
        {
          fieldKey: 'airportOfEntry',
          type: EDITOR_TYPES.DROPDOWN,
          isDisabled: true,
          options: _airportSettingStore.airportOfEntry,
        },
        {
          fieldKey: 'customsAvailableAtAirport',
          type: EDITOR_TYPES.SELECT_CONTROL,
          isBoolean: true,
          containerClass: classes.containerClass,
        },
        {
          fieldKey: 'customOfficerDispacthedFromAirport',
          type: EDITOR_TYPES.DROPDOWN,
          options: _entityMapStore.airports,
        },
        {
          fieldKey: 'appliedCustomsLocationInformations',
          type: EDITOR_TYPES.DROPDOWN,
          multiple: true,
          options: _entityMapStore.customLocation,
        },
        {
          fieldKey: 'customsClearanceFBOs',
          type: EDITOR_TYPES.DROPDOWN,
          multiple: true,
          options: _entityMapStore.vendorLocations,
        },
        {
          fieldKey: 'clearanceLocationSpecifics',
          type: EDITOR_TYPES.TEXT_FIELD,
        },
        {
          fieldKey: 'gaClearanceAvailable',
          isBoolean: true,
          type: EDITOR_TYPES.SELECT_CONTROL,
          containerClass: classes.containerClass,
        },
        {
          fieldKey: 'maximumPersonsOnBoardAllowedForGAClearance',
          type: EDITOR_TYPES.TEXT_FIELD,
        },
        {
          fieldKey: 'appliedMaxPOBAltClearanceOptions',
          type: EDITOR_TYPES.DROPDOWN,
          multiple: true,
          options: _entityMapStore.maxPOBOptions,
        },
      ],
    },
  ];
  return (
    <ViewInputControlsGroup
      groupInputControls={groupInputControls()}
      field={useUpsert.getField}
      isEditing={useUpsert.isEditable}
      isLoading={useUpsert.isLoading}
      onValueChange={onValueChange}
      onFocus={onFocus}
      onSearch={onSearch}
    />
  );
};

export default withFormFields(
  inject('airportSettingsStore', 'airportStore', 'entityMapStore')(observer(GeneralInfo)),
  generalFields
);
