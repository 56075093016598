import React, { FC, useEffect, useRef, useState } from 'react';
import {
  AIRPORT_HOURS_STATUS,
  AirportHourReviewModel,
  AirportHoursStore,
  AirportModuleSecurity,
  AirportSettingsStore,
  AirportStore,
  APPROVAL_MERGE_STATUS,
  REVIEW_COMPARISON_FILTERS,
} from '../Shared';
import { ISearchHeaderRef, SearchHeaderV2 } from '@wings-shared/form-controls';
import { agGridUtilities, CustomAgGridReact, useAgGrid, useGridState } from '@wings-shared/custom-ag-grid';
import { useConfirmDialog, useUnsubscribe } from '@wings-shared/hooks';
import {
  DATE_FORMAT,
  GRID_ACTIONS,
  GridPagination,
  IAPIGridRequest,
  IClasses,
  UIStore,
  Utilities,
  ViewPermission,
} from '@wings-shared/core';
import { ColDef, GridOptions, RowNode } from 'ag-grid-community';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { Grid, Tooltip, Typography } from '@material-ui/core';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import { CancelOutlined, RemoveRedEyeOutlined } from '@material-ui/icons';
import { finalize, takeUntil } from 'rxjs/operators';
import { Dialog } from '@uvgo-shared/dialog';
import { AlertStore } from '@uvgo-shared/alert';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { SidebarStore } from '@wings-shared/layout';
import { inject, observer } from 'mobx-react';
import { comparisonType, getGridData, gridFilters, isDataMerged, isDataRejected, mergeStatusOptions } from './fields';
import { AirportHoursReviewStatus } from './Components';
import { useStyles } from './AirportHoursReview.styles';

interface Props {
  airportStore?: AirportStore;
  airportSettingsStore?: AirportSettingsStore;
  sidebarStore?: typeof SidebarStore;
  airportHoursStore?: AirportHoursStore;
}

const AirportHoursReview: FC<Props> = ({ airportStore, airportHoursStore, airportSettingsStore }) => {
  const searchHeaderRef = useRef<ISearchHeaderRef>();
  const classes = useStyles();
  const gridState = useGridState();
  const unsubscribe = useUnsubscribe();
  const agGrid = useAgGrid<REVIEW_COMPARISON_FILTERS, AirportHourReviewModel>(gridFilters, gridState);
  const [ entityOptions, setEntityOptions ] = useState([]);
  const _airportStore = airportStore as AirportStore;
  const _airportHoursStore = airportHoursStore as AirportHoursStore;
  const _airportSettingsStore = airportSettingsStore as AirportSettingsStore;
  const _useConfirmDialog = useConfirmDialog();

  useEffect(() => {
    loadInitialData();
  }, []);

  useEffect(() => {
    loadEntityOptions();
  }, [ searchHeaderRef.current?.selectedOption, searchHeaderRef.current?.searchValue ]);

  /* istanbul ignore next */
  const getFilterPropertyName = (selectedOption): any => {
    switch (selectedOption) {
      case REVIEW_COMPARISON_FILTERS.AIRPORT:
        return 'airportId';
      case REVIEW_COMPARISON_FILTERS.AIRPORT_HOURS_TYPE:
        return 'airportHourTypeId';
      case REVIEW_COMPARISON_FILTERS.APPROVAL_STATUS:
        return 'mergeStatusId';
    }
  };

  /* istanbul ignore next */
  const filterCollection = (): IAPIGridRequest => {
    const _selectedOption = searchHeaderRef.current?.selectedOption;
    const searchChips: any = searchHeaderRef.current?.getFilters().chipValue?.valueOf();
    const _searchValue = searchChips.map(x => {
      return Utilities.isEqual(searchHeaderRef.current?.selectedOption, REVIEW_COMPARISON_FILTERS.APPROVAL_STATUS)
        ? x.value
        : x.id;
    });
    if (!_searchValue) {
      return {};
    }
    return {
      filterCollection: JSON.stringify([{ [getFilterPropertyName(_selectedOption)]: _searchValue[0] }]),
    };
  };

  /* istanbul ignore next */
  const loadInitialData = (pageRequest?: IAPIGridRequest) => {
    const request: IAPIGridRequest = {
      pageSize: gridState.pagination.pageSize,
      ...pageRequest,
      ...filterCollection(),
      ...agGrid.filtersApi.gridSortFilters(),
    };
    UIStore.setPageLoader(true);
    _airportStore
      .getAirportHoursReview(request)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(tableData => {
        const transformedData = getGridData(tableData.results, [ 1 ], false);
        gridState.setGridData(transformedData);
        gridState.setPagination(new GridPagination({ ...tableData }));
        const selectAll = gridState.data.some(x => !Utilities.isEqual(x.faaMergeStatus, APPROVAL_MERGE_STATUS.MERGED));
        gridState.setAllowSelectAll(selectAll);
      });
  };

  /* istanbul ignore next */
  const loadEntityOptions = () => {
    let observableOf;

    switch (searchHeaderRef.current?.selectedOption) {
      case REVIEW_COMPARISON_FILTERS.APPROVAL_STATUS:
        setEntityOptions(mergeStatusOptions as any);
        break;

      case REVIEW_COMPARISON_FILTERS.AIRPORT_HOURS_TYPE:
        if (_airportSettingsStore.airportHourSubTypes) {
          observableOf = _airportSettingsStore.loadAirportHourTypes();
        }
        break;

      case REVIEW_COMPARISON_FILTERS.AIRPORT:
        const _searchValue = searchHeaderRef.current?.searchValue;
        if (!_searchValue) {
          setEntityOptions([]);
          return;
        }
        observableOf = _airportHoursStore.searchWingsAirports(_searchValue, true);
        break;
      default:
        break;
    }
    if (observableOf) {
      UIStore.setPageLoader(true);
      observableOf
        .pipe(
          takeUntil(unsubscribe.destroy$),
          finalize(() => UIStore.setPageLoader(false))
        )
        .subscribe(response => setEntityOptions(response));
    }
  };

  const gridActions = (gridAction: GRID_ACTIONS, rowIndex: number): void => {
    if (rowIndex === null) {
      return;
    }
    switch (gridAction) {
      case GRID_ACTIONS.EDIT:
        agGrid._startEditingCell(rowIndex, columnDefs[0].field || '');
        break;
      default:
        agGrid.cancelEditing(rowIndex);
        break;
    }
  };

  const ValueDisplay = ({ title, oldValue, newValue, formatFn, showHeaders = true }) => (
    <Grid container>
      {/* Title Column */}
      <Grid item xs={4}>
        {showHeaders && (
          <Typography variant="subtitle1" className={classes.boldSubtitle}>
            Name
          </Typography>
        )}
        <Typography variant="subtitle2">{title}</Typography>
      </Grid>
      {/* Old Value Column */}
      <Grid item xs={4}>
        {showHeaders && (
          <Typography variant="subtitle1" className={classes.boldSubtitle}>
            Old Value
          </Typography>
        )}
        {oldValue?.length > 0 ? (
          <Typography variant="subtitle2">{oldValue.map(formatFn).join(', ')}</Typography>
        ) : (
          <Typography>-</Typography>
        )}
      </Grid>
      {/* New Value Column */}
      <Grid item xs={4}>
        {showHeaders && (
          <Typography variant="subtitle1" className={classes.boldSubtitle}>
            New Value
          </Typography>
        )}
        {newValue?.length > 0 ? (
          <Typography variant="subtitle2">{newValue.map(formatFn).join(', ')}</Typography>
        ) : (
          <Typography>-</Typography>
        )}
      </Grid>
    </Grid>
  );

  const dialogContent = data => {
    return (
      <Grid container>
        {/* Days of Weeks */}
        {data?.daysOfWeeks && (data.daysOfWeeks.oldValue?.length > 0 || data.daysOfWeeks.newValue?.length > 0) && (
          <Grid item xs={12}>
            {ValueDisplay({
              title: 'Days of Week',
              oldValue: data.daysOfWeeks.oldValue,
              newValue: data.daysOfWeeks.newValue,
              formatFn: item => item?.dayOfWeek?.name || '',
            })}
          </Grid>
        )}

        {/* Airport Hours Condition Values */}
        {data?.airportHoursConditionValues &&
          (data.airportHoursConditionValues.oldValue?.length > 0 ||
            data.airportHoursConditionValues.newValue?.length > 0) && (
            <Grid item xs={12}>
              {ValueDisplay({
                title: 'Airport Hours Condition Values',
                oldValue: data.airportHoursConditionValues.oldValue,
                newValue: data.airportHoursConditionValues.newValue,
                formatFn: item => `${item?.entityValue || ''}(${item?.entityValueCode || ''})`,
              })}
            </Grid>
          )}

        {/* Airport Hour Times */}
        {data?.airportHourTimes && Boolean(Object.keys(data?.airportHourTimes).length) && (
          <Grid item xs={12}>
            {/* Start Time and End Time */}
            {ValueDisplay({
              title: 'Start Time',
              oldValue: [ data.airportHourTimes.oldValue ],
              newValue: [ data.airportHourTimes.newValue ],
              formatFn: item =>
                item?.startTime?.time
                  ? Utilities.getformattedDate(item.startTime.time, DATE_FORMAT.API_TIME_FORMAT)
                  : '-',
              showHeaders: true,
            })}
            {ValueDisplay({
              title: 'End Time',
              oldValue: [ data.airportHourTimes.oldValue ],
              newValue: [ data.airportHourTimes.newValue ],
              formatFn: item =>
                item?.endTime?.time ? Utilities.getformattedDate(item.endTime?.time, DATE_FORMAT.API_TIME_FORMAT) : '-',
              showHeaders: false,
            })}
          </Grid>
        )}
      </Grid>
    );
  };

  const customErrorMessage = (error, id) => {
    if (error.message.toLowerCase().includes(`UplinkProperty ${id} Id not found`.toLowerCase())) {
      AlertStore.critical('Updates have been made to this record. Please refresh the page.');
      return;
    }
    AlertStore.critical(error.message);
  };

  const getApprovalsData = data => {
    UIStore.setPageLoader(true);
    _airportStore
      ?.getAirportHoursReviewList(data[0].uplinkStagingPropertyId)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe({
        next: response => {
          ModalStore.open(
            <Dialog
              title={'Approval Details'}
              open={true}
              onClose={() => ModalStore.close()}
              dialogContent={() => dialogContent(response)}
              disableBackdropClick={true}
            />
          );
        },
        error: error => {
          customErrorMessage(error, data[0].uplinkStagingPropertyId);
        },
      });
  };

  const ConfirmationModel = (data: AirportHourReviewModel, isApprove: boolean): void => {
    _useConfirmDialog.confirmAction(
      () => {
        approveRejectRecord([ data ], isApprove), ModalStore.close();
      },
      {
        title: 'Confirm Action',
        message: isApprove
          ? 'Are you sure you want to approve the changes?'
          : 'Are you sure you want to reject the changes?',
      }
    );
  };

  const approveRejectRecord = (data, isApprove: boolean): void => {
    UIStore.setPageLoader(true);

    const id = data[0]?.airportHourStagingId || data[0]?.uplinkStagingPropertyId;
    const operationObservable = data[0]?.airportHourStagingId
      ? isApprove
        ? airportStore?.approveAirportHourStaging(id)
        : airportStore?.rejectAirportHourStaging(id)
      : isApprove
        ? airportStore?.approveUplinkStagingProperty(id)
        : airportStore?.rejectUplinkStagingProperty(id);

    operationObservable
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: response => {
          if (response.hasErrors) {
            return;
          }
          gridState.gridApi.deselectAll();
          loadInitialData();
        },
        error: error => {
          customErrorMessage(error, id);
        },
      });
  };

  const isDataDisabled = data => {
    if (isDataMerged(data.mergeStatus) || isDataRejected(data.mergeStatus)) {
      return true;
    }
    if (
      (Utilities.isEqual(data.comparisionType, AIRPORT_HOURS_STATUS.ADDED) && data.airportHourStagingId) ||
      Utilities.isEqual(data.comparisionType, AIRPORT_HOURS_STATUS.MODIFIED)
    ) {
      return false;
    }
    return true;
  };

  const reviewActions = (rowIndex: number, { data }: RowNode) => {
    const isDisabled = isDataDisabled(data);

    return (
      <div className={classes.actionButton}>
        <ViewPermission hasPermission={AirportModuleSecurity.isEditable && data.isList}>
          <PrimaryButton
            variant="outlined"
            color="primary"
            onClick={() => {
              getApprovalsData([ data ]);
            }}
          >
            <Tooltip title="Details">
              <RemoveRedEyeOutlined className={classes.icons} />
            </Tooltip>
          </PrimaryButton>
        </ViewPermission>
        <ViewPermission hasPermission={AirportModuleSecurity.isEditable}>
          <PrimaryButton
            variant="outlined"
            color="primary"
            disabled={isDisabled}
            onClick={() => ConfirmationModel(data, true)}
          >
            <Tooltip title="Approve">
              <CheckOutlinedIcon className={classes.icons} />
            </Tooltip>
          </PrimaryButton>

          <PrimaryButton
            variant="outlined"
            color="primary"
            disabled={isDisabled}
            onClick={() => ConfirmationModel(data, false)}
          >
            <Tooltip title="Reject">
              <CancelOutlined className={classes.icons} />
            </Tooltip>
          </PrimaryButton>
        </ViewPermission>
      </div>
    );
  };

  const columnDefs: ColDef[] = [
    {
      headerName: 'Airport Hour Type',
      field: 'airportHoursType',
      minWidth: 100,
      valueFormatter: ({ value }) => value?.name || '',
      headerTooltip: 'Airport Hour Type',
    },
    {
      headerName: 'Sequence ID',
      field: 'cappsSequenceId',
      headerTooltip: 'Sequence ID',
      minWidth: 80,
    },
    {
      headerName: 'Property Name',
      field: 'propertyName',
      minWidth: 150,
      headerTooltip: 'Property Name',
    },
    {
      headerName: 'Old Value',
      field: 'oldValue',
      minWidth: 150,
      headerTooltip: 'Old Value',
    },
    {
      headerName: 'New Value',
      field: 'newValue',
      minWidth: 150,
      headerTooltip: 'New Value',
    },
    {
      headerName: 'Comparison Type',
      field: 'comparisionType',
      minWidth: 120,
      headerTooltip: 'Comparison Type',
      valueFormatter: ({ data }) => {
        return data?.airportHourStagingId ? comparisonType[data?.comparisionType] : '';
      },
    },
    {
      headerName: 'Status',
      field: 'mergeStatus',
      minWidth: 120,
      editable: false,
      cellRenderer: 'viewRenderer',
      cellRendererParams: {
        getViewRenderer: (_, rowNode) => <AirportHoursReviewStatus {...rowNode} fieldKey="mergeStatus" />,
      },
      headerTooltip: 'Status',
    },
    ...agGrid.auditFields(gridState.isRowEditing),
    {
      ...agGrid.actionColumn({
        cellRenderer: 'viewRenderer',
        maxWidth: 220,
        minWidth: 220,
        cellRendererParams: {
          getViewRenderer: (rowIndex: number, node: RowNode, classes: IClasses) => reviewActions(rowIndex, node),
          onAction: (action: GRID_ACTIONS, rowIndex: number) => {
            gridActions(action, rowIndex);
          },
        },
      }),
    },
  ];

  /* istanbul ignore next */
  const gridOptions = (): GridOptions => {
    const baseOptions: Partial<GridOptions> = agGrid.gridOptionsBase({
      context: {
        onDropDownChange: () => gridState.setHasError(Utilities.hasInvalidRowData(gridState.gridApi)),
        onInputChange: () => gridState.setHasError(Utilities.hasInvalidRowData(gridState.gridApi)),
      },
      columnDefs,
      isEditable: AirportModuleSecurity.isEditable,
    });

    return {
      ...baseOptions,
      defaultColDef: {
        ...baseOptions.defaultColDef,
        suppressMovable: true,
      },
      pagination: false,
      suppressScrollOnNewData: true,
      isExternalFilterPresent: () => false,
      suppressCellSelection: true,
      suppressClickEdit: true,
      suppressRowHoverHighlight: true,
      onFilterChanged: () => loadInitialData({ pageNumber: 1 }),
      onSortChanged: e => {
        agGrid.filtersApi.onSortChanged(e);
        loadInitialData({ pageNumber: 1 });
      },

      groupDefaultExpanded: -1, // Expand all groups by default
      getDataPath: data => {
        return data.path;
      },
      treeData: true,
      autoGroupColumnDef: {
        headerName: 'Airport',
        field: 'airport',
        minWidth: 180,
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
          suppressCount: true,
        },
        valueFormatter: ({ data }) => {
          return data?.airport.label || '';
        },
      },
    };
  };

  return (
    <>
      <SearchHeaderV2
        placeHolder="Start typing to search"
        ref={searchHeaderRef}
        onExpandCollapse={agGrid.autoSizeColumns}
        selectInputs={[
          agGridUtilities.createSelectOption(
            REVIEW_COMPARISON_FILTERS,
            REVIEW_COMPARISON_FILTERS.AIRPORT,
            'defaultOption'
          ),
        ]}
        isChipInputControl={true}
        chipInputProps={{
          options: entityOptions,
          allowOnlySingleSelect: true,
        }}
        onFilterChange={isInitEvent =>
          loadInitialData({ pageNumber: isInitEvent ? gridState.pagination.pageNumber : 1 })
        }
      />
      <CustomAgGridReact
        isRowEditing={gridState.isRowEditing}
        rowData={gridState.data}
        gridOptions={gridOptions()}
        serverPagination={true}
        paginationData={gridState.pagination}
        onPaginationChange={request => loadInitialData(request)}
        classes={{ customHeight: classes.customHeight }}
      />
    </>
  );
};

export default inject('airportStore', 'airportSettingsStore', 'airportHoursStore')(observer(AirportHoursReview));
