import { GRID_ACTIONS, Utilities, UIStore, baseEntitySearchFilters } from '@wings-shared/core';
import { DetailsEditorHeaderSection, DetailsEditorWrapper, SidebarStore, ConfirmNavigate } from '@wings-shared/layout';
import { useBaseUpsertComponent, VIEW_MODE } from '@wings/shared';
import { inject, observer } from 'mobx-react';
import React, { FC, ReactNode, useEffect, useRef, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Route, Routes } from 'react-router-dom';
import GeneralInfo from './GeneralInfo/GeneralInfo';
import { useStyles } from './CustomDetails.styles';
import {
  AirportModuleSecurity,
  customDetailSidebarOptions,
  AirportStore,
  AirportCustomGeneralModel,
  AirportModel,
  AirportCustomDetailStore,
  AirportCustomModel,
} from '../../../Shared';
import { finalize, takeUntil } from 'rxjs/operators';
import { useUnsubscribe } from '@wings-shared/hooks';
interface Props {
  sidebarStore?: typeof SidebarStore;
  title?: string;
  airportStore?: AirportStore;
  airportCustomDetailStore?: AirportCustomDetailStore;
}

const CustomDetails: FC<Props> = ({ ...props }) => {
  const historyBasePath = useRef('');
  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();
  const useUpsert = useBaseUpsertComponent(params, {}, baseEntitySearchFilters);
  const unsubscribe = useUnsubscribe();
  const _sidebarStore = props.sidebarStore as typeof SidebarStore;
  const basePath = useMemo(() => {
    const pathList = location.pathname.split('/');
    const indexOfOR = pathList.indexOf('custom-detail');
    return pathList.slice(0, indexOfOR + 1).join('/');
  }, [ location.pathname ]);
  useEffect(() => {
    historyBasePath.current = _sidebarStore.basePath;
    _sidebarStore.setNavLinks(customDetailSidebarOptions(), basePath);
  }, []);

  /* istanbul ignore next */
  useEffect(() => {
    useUpsert.setViewMode((params.viewMode?.toUpperCase() as VIEW_MODE) || VIEW_MODE.DETAILS);
  }, []);

  /* istanbul ignore next */
  const selectedAirport: AirportModel = props.airportStore?.selectedAirport as AirportModel;

  const setDetailMode = () => {
    if (Utilities.isEqual(params.viewMode?.toUpperCase() as VIEW_MODE, VIEW_MODE.DETAILS)) {
      useUpsert.setViewMode(VIEW_MODE.DETAILS);
    }
  };
  const saveGeneral = () => {
    const formData = useUpsert.form.values();
    const request = new AirportCustomGeneralModel({
      ...formData,
      airportId: Number(params.airportId),
      id: selectedAirport.customs?.generalInformation?.id,
    });
    UIStore.setPageLoader(true);
    props.airportCustomDetailStore
      ?.upsertGeneral(request.serialize())
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (generalInformation: AirportCustomGeneralModel) => {
          props.airportStore?.setSelectedAirport({
            ...selectedAirport,
            customs: new AirportCustomModel({ ...selectedAirport.customs, generalInformation }),
          });
          useUpsert.form.reset();
          useUpsert.setFormValues(generalInformation);
          setDetailMode();
        },
        error: error => {
          useUpsert.showAlert(error.message, 'upsertGeneral');
        },
      });
  };

  const onAction = (action: GRID_ACTIONS): void => {
    const { airportId, icao, viewMode } = params;
    switch (action) {
      case GRID_ACTIONS.SAVE:
        saveGeneral();
        break;
      case GRID_ACTIONS.EDIT:
        useUpsert.setViewMode(VIEW_MODE.EDIT);
        break;
      case GRID_ACTIONS.CANCEL:
      default:
        useUpsert.setFormValues(selectedAirport?.customs?.generalInformation);
        if (Utilities.isEqual(params.viewMode?.toUpperCase() as VIEW_MODE, VIEW_MODE.DETAILS)) {
          useUpsert.setViewMode(VIEW_MODE.DETAILS);
          return;
        }
        navigate(`/airports/upsert/${airportId}/${icao}/${viewMode}`);
        break;
    }
  };
  const headerActions = (): ReactNode => {
    return (
      <DetailsEditorHeaderSection
        title={props?.airportStore?.selectedAirport?.title}
        backNavTitle="Airport Details"
        isEditMode={useUpsert.isEditView}
        onAction={onAction}
        backNavLink={`/${historyBasePath.current}`}
        hasEditPermission={AirportModuleSecurity.isEditable}
        disableActions={useUpsert.form.hasError || UIStore.pageLoading || !useUpsert.form.changed}
        showBreadcrumb={true}
      />
    );
  };

  return (
    <ConfirmNavigate isBlocker={useUpsert.form.touched || useUpsert.form.changed}>
      <DetailsEditorWrapper
        headerActions={headerActions()}
        isEditMode={useUpsert.isEditView}
        classes={{ container: classes.editorWrapperContainer, headerActionsEditMode: classes.headerActionsEditMode }}
        isBreadCrumb={true}
      >
        <Routes>
          <Route path="general" element={<GeneralInfo useUpsert={useUpsert} key="general" />} />
        </Routes>
      </DetailsEditorWrapper>
    </ConfirmNavigate>
  );
};

export default inject('sidebarStore', 'airportStore', 'airportCustomDetailStore')(observer(CustomDetails)) as FC<Props>;
